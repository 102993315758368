import { useMemo, useState } from 'react'

export interface IDataType {
  pnl?: {
    pnlColumns: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: typeof P_AND_L_COLUMNS_OPTIONS
    }
  }
}

export interface IOptionType {
  withRankOptions?: true
}

const P_AND_L_COLUMNS_OPTIONS = [
  { id: 'pAndLSales', parentId: 'root', displayName: 'Current Period' },
  { id: 'pAndLYoySales', parentId: 'root', displayName: 'Prior Year PTD' },
  {
    id: 'pAndLYoyCompare',
    parentId: 'root',
    displayName: 'Current Period vs PY PTD',
  },
  { id: 'pAndLYtdSales', parentId: 'root', displayName: 'Current YTD' },
  { id: 'pAndLLYYtdSales', parentId: 'root', displayName: 'Prior YTD' },
  {
    id: 'pAndLLYYtdCompare',
    parentId: 'root',
    displayName: 'Current YTD vs Prior YTD',
  },
  {
    id: 'pAndLPriorPeriodSales',
    parentId: 'root',
    displayName: 'Prior Period',
  },
  {
    id: 'pAndLPriorPeriodCompare',
    parentId: 'root',
    displayName: 'Current Period vs Prior Period',
  },
]

const usePnl = () => {
  const pnlColumnsHook = useState([
    ['pAndLSales'],
    ['pAndLYoySales'],
    ['pAndLYoyCompare'],
  ])

  return {
    pnl: useMemo(
      (): IDataType['pnl'] => ({
        pnlColumns: {
          value: pnlColumnsHook[0],
          onChange: pnlColumnsHook[1],
          options: P_AND_L_COLUMNS_OPTIONS,
        },
      }),
      [pnlColumnsHook],
    ),
  }
}

export default usePnl
