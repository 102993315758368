import { IApiDataType } from '../types'
import {
  useBbbCompanyFilter,
  useBbbCorporateOpportunitiesFilter,
  useBbbExecutiveSummaryFilter,
  useBbbStoreOpportunitiesFilter,
} from './useBbbCompanyFilter'
import {
  useBbbCorporateFilter,
  useBbbPnlCorporateFilter,
} from './useBbbCorporateFilter'
import {
  useBbbCorporateWithoutBreakdownFilter,
  useBbbPnlCorporateWithoutBreakdownFilter,
} from './useBbbCorporateWithoutBreakdownFilter'
import useCompanyFilter from './useCompanyFilter'
import useCorporateFilter from './useCorporateFilter'
import useCorporateWithoutBreakdownFilter from './useCorporateWithoutBreakdownFilter'
import useDrgGroupFilter from './useDrgGroupFilter'
import useFwWingstopCorporateFilter from './useFwWingstopCorporateFilter'
import useFwWingstopCorporateWithoutBreakdownFilter from './useFwWingstopCorporateWithoutBreakdownFilter'
import useJscBkGroupFilter from './useJscBkGroupFilter'
import useLegalEntityFilter from './useLegalEntityFilter'
import useLfrBrandsGroupFilter from './useLfrBrandsGroupFilter'
import useLfrGfpAllGroupFilter from './useLfrGfpAllGroupFilter'
import useLfrGroupFilter from './useLfrGroupFilter'
import {
  useBbbCompanyLocationFilter,
  useLocationFilter,
} from './useLocationFilter'

export type IApiKeyType = keyof typeof api

const api = {
  lfrGroupFilter: useLfrGroupFilter,
  lfrBrandsGroupFilter: useLfrBrandsGroupFilter,
  corporateFilter: useCorporateFilter,
  locationFilter: useLocationFilter,
  jscBkGroupFilter: useJscBkGroupFilter,
  drgGroupFilter: useDrgGroupFilter,
  lfrGfpAllGroupFilter: useLfrGfpAllGroupFilter,
  corporateWithoutBreakdownFilter: useCorporateWithoutBreakdownFilter,
  bbbCorporateFilter: useBbbCorporateFilter,
  bbbCorporateWithoutBreakdownFilter: useBbbCorporateWithoutBreakdownFilter,
  bbbPnlCorporateFilter: useBbbPnlCorporateFilter,
  bbbPnlCorporateWithoutBreakdownFilter:
    useBbbPnlCorporateWithoutBreakdownFilter,
  bbbCompanyFilter: useBbbCompanyFilter,
  bbbExecutiveSummaryFilter: useBbbExecutiveSummaryFilter,
  bbbCorporateOpportunitiesFilter: useBbbCorporateOpportunitiesFilter,
  bbbStoreOpportunitiesFilter: useBbbStoreOpportunitiesFilter,
  bbbCompanyLocationFilter: useBbbCompanyLocationFilter,
  fwWingstopCorporateFilter: useFwWingstopCorporateFilter,
  fwWingstopCorporateWithoutBreakdownFilter:
    useFwWingstopCorporateWithoutBreakdownFilter,
  companyFilter: useCompanyFilter,
  legalEntityFilter: useLegalEntityFilter,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
