import _ from 'lodash'
import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useGroupFilter } from '../../../../groupFilter'
import useMetricValuesQuery from '../../../../hooks/useMetricValuesQuery'
import calc, { LOADING } from '../../../../utils/calc'
import { useVariables } from '../../../../variables'
import { IApiDataType } from '../../../types'
import useGlCodes from './useGlCodes'

const buildPnl = (exrayCategory: 'Expenses' | 'Food Cost' | 'Labor Cost') => {
  const usePnl = (): IApiDataType => {
    const { variables } = useVariables()
    const { groupFilter } = useGroupFilter()
    const isCorporate = Boolean(groupFilter?.list)
    const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory)
    const {
      data,
      yoyData,
      priorData,
      loading: metricValuesLoading,
    } = useMetricValuesQuery({
      groupFilterTypes: ['location', 'locationGroup'],
      metrics: [
        ...glCodes.map((g) => g.metricCode).filter(Boolean),
        ...glCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'yoy' })
          .filter(Boolean),
        ...glCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'prior' })
          .filter(Boolean),
      ] as string[],
      fields: ['metricSummaryData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            ...(isCorporate
              ? {
                  location_group_ids: groupFilter?.ids,
                }
              : {
                  location_ids: groupFilter?.ids,
                }),
          },
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

    return {
      data: useMemo(() => {
        if (!data) return null

        const currentPeriod = variables.date?.getInfo(0)
        const name = isCorporate
          ? ''
          : `${groupFilter?.label.replace(/\d+ -/, '')}'s`
        const [total, yoyTotal, priorTotal] = [data, yoyData, priorData].map(
          (data) =>
            (data || []).reduce(
              (result, d) =>
                Object.values(d.metricSummaryData || {}).reduce(
                  (subResult, { value }) => subResult + (value || 0),
                  result,
                ),
              0,
            ) * -1,
        )
        const [isCurrentNotEmpty, isYoyNotEmpty, isPriorNotEmpty] = [
          data,
          yoyData,
          priorData,
        ].map((data) =>
          (data || []).reduce(
            (result, d) =>
              result ||
              Object.values(d.metricSummaryData || {}).some(
                ({ value }) => !_.isNil(value),
              ),
            false,
          ),
        )

        if (!isCurrentNotEmpty) return null

        const dateLabel = (() => {
          switch (variables.date?.value.type) {
            case 'year':
              return 'Year'
            case 'quarter':
              return 'Quarter'
            case 'period':
              return 'Period'
            case 'week':
            case 'this_week':
            case 'last_week':
              return 'Week'
            default:
              return 'Period'
          }
        })()

        const yoyDiff = !isYoyNotEmpty
          ? null
          : (calc(
              calc(total, '-', yoyTotal),
              'percentageOf',
              yoyTotal,
            ) as number)

        const priorDiff = !isPriorNotEmpty
          ? null
          : (calc(
              calc(total, '-', priorTotal),
              'percentageOf',
              priorTotal,
            ) as number)

        return {
          title: exrayCategory,
          total,
          colorValue: yoyDiff ?? priorDiff,
          summary: `${name} ${exrayCategory} in ${
            currentPeriod?.displayName
          } was ${toUsdString(total / 100)}`,
          detail: `
            <ul>
              ${[
                {
                  value: isYoyNotEmpty ? yoyTotal : null,
                  label: 'Prior Year',
                },
                {
                  value: isPriorNotEmpty ? priorTotal : null,
                  label: `Prior ${dateLabel}`,
                },
              ]
                .map(({ value, label }) => {
                  const diff = calc(
                    calc(total, '-', value),
                    'percentageOf',
                    value,
                  )

                  if (diff === null || diff === LOADING) return

                  return `
                    <li>
                      ${
                        diff >= 0 ? 'Increased' : 'Decreased'
                      } ${toPercentString(Math.abs(diff))} from ${label}
                    </li>
                  `
                })
                .filter(Boolean)
                .join('\n')}
            </ul>
          `,
          hasDetails: true,
        }
      }, [data, yoyData, priorData, variables, groupFilter, isCorporate]),
      loading: glCodesLoading || metricValuesLoading,
    }
  }

  return usePnl
}

export default buildPnl
