import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import useBrands from 'pared/layouts/hooks/useBrands'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'editLocationGroup'
  locationGroupId: number
  locationGroupType: string
  locationGroupName: string
  locationIds: number[]
}

export interface IDataType {
  locationGroupType: string
  value: string
  onChange: (value: IDataType['value']) => void
  loading: boolean
  errorMessage?: string
  submit: () => Promise<void>
  deletable: boolean
  delete: () => Promise<void>
}

const updateMutate = gql`
  mutation UpdateGlGroup($id: BigInt!, $name: String!, $now: Datetime!) {
    updateGlGroup(input: { id: $id, patch: { name: $name, updatedAt: $now } }) {
      clientMutationId
    }
  }
`

const deleteMutate = gql`
  mutation DeleteGlGroup($id: BigInt!) {
    deleteGlGroup(input: { id: $id }) {
      clientMutationId
    }
  }
`

const useEditGlGroup = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const editGlGroupHook = useState<string>('')
  const isSubmittingHook = useState(false)
  const prevManageType = usePrevious(option?.manageType)
  const { brand } = useBrands()
  const [updateGlGroup, { loading: updateLoading }] = useMutation(
    updateMutate,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )
  const [deleteGlGroup, { loading: deleteLoading }] = useMutation(
    deleteMutate,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )
  const loading = updateLoading || deleteLoading
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listGlGroups.nodes || []).reduce(
            (result, n) =>
              result.some((r) => r === n.name) ? result : [...result, n.name],
            [] as string[],
          ),
    [data, option],
  )

  useEffect(() => {
    if (prevManageType !== option?.manageType) {
      if (option?.manageType === 'editGlGroup')
        editGlGroupHook[1](option.pAndLName)
      else {
        editGlGroupHook[1]('')
        isSubmittingHook[1](false)
      }
    }
  }, [option, prevManageType, editGlGroupHook, isSubmittingHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'editGlGroup') return

    const [value, onChange] = editGlGroupHook
    const [isSubmitting, setIsSubmitting] = isSubmittingHook
    const validator = (isSubmitting: boolean) => {
      if (isSubmitting) {
        if (!value) return `Couldn't be empty`

        if (value === option.pAndLName) return `Couldn't use the original name`
      }

      if (value !== option.pAndLName && options.includes(value))
        return `${value} exists`
    }

    return {
      glGroupName: option.pAndLName,
      value,
      onChange,
      loading,
      errorMessage: validator(isSubmitting),
      submit: async () => {
        const errorMessage = validator(true)
        const hasError = Boolean(errorMessage)

        if (!isSubmitting) setIsSubmitting(true)

        if (loading || hasError) return

        await updateGlGroup({
          variables: {
            id: option.id.split('-')[1],
            name: value,
            now: moment.utc().format(),
          },
        })
        setIsSubmitting(false)
        close()
      },
      deletable: !option.subRows?.length,
      delete: async () => {
        await deleteGlGroup({
          variables: {
            id: option.id.split('-')[1],
          },
        })
        close()
      },
    }
  }, [
    option,
    close,
    editGlGroupHook,
    isSubmittingHook,
    loading,
    brand,
    options,
  ])
}

export default useEditGlGroup
