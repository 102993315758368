import { Box, Button } from '@mui/material'

import Folder from './Folder'

// Helper function to find a folder by ID
const findFolderById = (folders, folderId) => {
  for (const folder of folders) {
    if (folder.id == folderId) return folder
    if (folder.children) {
      const foundInChildren = findFolderById(folder.children, folderId)
      if (foundInChildren) return foundInChildren
    }
  }
  return null
}

// Helper function to remove a folder by ID from the folder tree
const removeFolderById = (folders, folderId) => {
  return folders
    .map((folder) => {
      if (folder.children) {
        folder.children = removeFolderById(folder.children, folderId)
      }
      return folder.id == folderId ? null : folder
    })
    .filter((folder) => folder !== null) // Filter out null values (removed folder)
}

// Helper function to add a folder to the target folder's children
const addFolderToTarget = (folders, draggedFolder, targetId) => {
  return folders.map((folder) => {
    if (folder.id == targetId) {
      if (draggedFolder) {
        draggedFolder.parentId = targetId // Set the parentId for the dragged folder
        folder.children = folder.children
          ? [...folder.children, draggedFolder]
          : [draggedFolder]
      }
    } else if (folder.children) {
      folder.children = addFolderToTarget(
        folder.children,
        draggedFolder,
        targetId,
      )
    }
    return folder
  })
}

const addDepth = (folder, depth) => {
  folder.depth = depth
  if (folder.children) {
    for (const childFolder of folder.children) {
      addDepth(childFolder, depth + 1)
    }
  }
}

const FolderContainer = ({ folders, setFolders }) => {
  const handleDragStart = (e, folderId) => {
    e.stopPropagation()
    e.dataTransfer.setData('folderId', folderId) // Store the folder ID in dataTransfer
  }

  const handleDragOver = (e) => {
    e.preventDefault() // Allow drop
  }

  const handleDrop = (e, targetId) => {
    e.stopPropagation()
    const draggedId = e.dataTransfer.getData('folderId') // Get the ID of the dragged folder
    if (draggedId == targetId) return // Prevent folder from being dropped into itself

    // Make a copy of the folders array to avoid mutation
    let updatedFolders = [...folders]

    // Prevent folder from being dropped into items or children
    const draggedFolder = findFolderById(updatedFolders, draggedId)
    const targetFolder = findFolderById(updatedFolders, targetId)
    if (targetFolder.type === 'Item') {
      return
    }

    const targetFolderInChildren = findFolderById(
      draggedFolder.children,
      targetId,
    )
    if (targetFolderInChildren) {
      return
    }

    // Remove the dragged folder from its current position
    updatedFolders = removeFolderById(updatedFolders, draggedId)

    // Add the dragged folder to the target folder's children
    updatedFolders = addFolderToTarget(updatedFolders, draggedFolder, targetId)

    // Update the state with the modified folder structure
    setFolders(updatedFolders) // This will trigger a re-render
  }

  const handleAddFolder = () => {
    const newFolder = {
      id: `folder-${Date.now()}`, // Unique ID based on current timestamp
      name: `New Category`,
      parentId: null,
      children: [],
      type: 'Folder',
    }
    setFolders((prevFolders) => [...prevFolders, newFolder]) // Add new folder to the root level
  }

  const handleDeleteFolder = (folderId) => {
    let updatedFolders = [...folders]
    updatedFolders = removeFolderById(folders, folderId)
    setFolders(updatedFolders)
  }

  const editFolderName = (folderId, name) => {
    let updatedFolders = [...folders]
    const editedFolder = findFolderById(updatedFolders, folderId)
    editedFolder.name = name
    setFolders(updatedFolders)
  }

  for (const folder of folders) {
    addDepth(folder, 0)
  }

  return (
    <Box>
      {/* Render root folders */}
      <Box>
        {folders
          .filter((folder) => folder.parentId === null)
          .map((folder) => (
            <Folder
              key={folder.id}
              folder={folder}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              editFolderName={editFolderName}
              deleteFolder={handleDeleteFolder}
            />
          ))}
      </Box>
      <Button
        variant="contained"
        sx={{ marginTop: 2, marginRight: 2 }}
        onClick={handleAddFolder}
      >
        Add Category
      </Button>
    </Box>
  )
}

export default FolderContainer
