import _ from 'lodash'
import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useGroupFilter } from '../../../../groupFilter'
import useMetricValuesQuery from '../../../../hooks/useMetricValuesQuery'
import calc, { LOADING } from '../../../../utils/calc'
import { useVariables } from '../../../../variables'
import useGlCodes from './useGlCodes'

const useCompDetail = () => {
  const { groupFilter } = useGroupFilter()
  const isCorporate = Boolean(groupFilter?.list)
  const { glCodes, loading: glCodesLoading } = useGlCodes('Comps & Discounts')
  const {
    data,
    priorData,
    loading: metricValuesLoading,
  } = useMetricValuesQuery({
    groupFilterTypes: ['location', 'locationGroup'],
    metrics: [
      ...glCodes.map((g) => g.metricCode).filter(Boolean),
      ...glCodes
        .map((g) => g.metricCode && { key: g.metricCode, type: 'prior' })
        .filter(Boolean),
    ] as string[],
    fields: ['metricSummaryData'],
    handler: (variables) => {
      const newVariables = {
        ...variables,
        iFilter: {
          ...variables.iFilter,
          ...(isCorporate
            ? {
                location_group_ids: groupFilter?.ids,
              }
            : {
                location_ids: groupFilter?.ids,
              }),
        },
      }

      return {
        variables: newVariables,
        skip:
          !groupFilter ||
          !newVariables.iStartDate ||
          !newVariables.iEndDate ||
          (newVariables.iFilter.metrics || []).length === 0,
      }
    },
  })

  return {
    compDetail: useMemo(() => {
      const [total, priorTotal] = [data, priorData].map((data) =>
        (data || []).reduce(
          (result, d) =>
            Object.values(d.metricSummaryData || {}).reduce(
              (subResult, { value }) => subResult + (value || 0),
              result,
            ),
          0,
        ),
      )
      const [isCurrentNotEmpty, isPriorNotEmpty] = [data, priorData].map(
        (data) =>
          (data || []).reduce(
            (result, d) =>
              result ||
              Object.values(d.metricSummaryData || {}).some(
                ({ value }) => !_.isNil(value),
              ),
            false,
          ),
      )

      if (!isCurrentNotEmpty || !isPriorNotEmpty) return null

      const diff = calc(
        calc(total, '-', priorTotal),
        'percentageOf',
        priorTotal,
      )

      if (diff === null || diff === LOADING) return

      return {
        // compDiff color uses opposite logic of salesDiff
        compDiff: -1 * diff,
        message: `Comps & Discounts ${
          diff >= 0 ? 'increased' : 'decreased'
        } ${toPercentString(Math.abs(diff))} vs Prior Period`,
      }
    }, [data, priorData]),
    loading: glCodesLoading || metricValuesLoading,
  }
}

const useSales = () => {
  const { variables } = useVariables()
  const { groupFilter } = useGroupFilter()
  const isCorporate = Boolean(groupFilter?.list)
  const { glCodes, loading: glCodesLoading } = useGlCodes('Sales')
  const {
    data,
    ytdData,
    priorYtdData,
    loading: metricValuesLoading,
  } = useMetricValuesQuery({
    groupFilterTypes: ['location', 'locationGroup'],
    metrics: [
      ...glCodes.map((g) => g.metricCode).filter(Boolean),
      ...glCodes
        .map((g) => g.metricCode && { key: g.metricCode, type: 'ytd' })
        .filter(Boolean),
      ...glCodes
        .map((g) => g.metricCode && { key: g.metricCode, type: 'prior ytd' })
        .filter(Boolean),
    ] as string[],
    fields: ['metricSummaryData'],
    handler: (variables) => {
      const newVariables = {
        ...variables,
        iFilter: {
          ...variables.iFilter,
          ...(isCorporate
            ? {
                location_group_ids: groupFilter?.ids,
              }
            : {
                location_ids: groupFilter?.ids,
              }),
        },
      }

      return {
        variables: newVariables,
        skip:
          !groupFilter ||
          !newVariables.iStartDate ||
          !newVariables.iEndDate ||
          (newVariables.iFilter.metrics || []).length === 0,
      }
    },
  })
  const { compDetail, loading: compDetailLoading } = useCompDetail()

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const name = isCorporate
        ? ''
        : `${groupFilter?.label.replace(/\d+ -/, '')}'s`
      const [total, ytdTotal, priorYtdTotal] = [
        data,
        ytdData,
        priorYtdData,
      ].map((data) =>
        (data || []).reduce(
          (result, d) =>
            Object.values(d.metricSummaryData || {}).reduce(
              (subResult, { value }) => subResult + (value || 0),
              result,
            ),
          0,
        ),
      )
      const [isCurrentNotEmpty, isYtdNotEmpty, isPriorYtdNotEmpty] = [
        data,
        ytdData,
        priorYtdData,
      ].map((data) =>
        (data || []).reduce(
          (result, d) =>
            result ||
            Object.values(d.metricSummaryData || {}).some(
              ({ value }) => !_.isNil(value),
            ),
          false,
        ),
      )

      if (!isCurrentNotEmpty) return null

      const salesDiff = calc(
        calc(ytdTotal, '-', priorYtdTotal),
        'percentageOf',
        priorYtdTotal,
      )

      return {
        title: 'Sales',
        total,
        colorValue: salesDiff ?? compDetail?.compDiff,
        summary: `${name} Sales in ${
          currentPeriod?.displayName
        } was ${toUsdString(total / 100)}`,
        detail: `
          <ul>
            ${
              !isYtdNotEmpty ||
              !isPriorYtdNotEmpty ||
              salesDiff === null ||
              salesDiff === LOADING
                ? ''
                : `
                  <li>
                    Sales ${
                      salesDiff >= 0 ? 'increased' : 'decreased'
                    } ${toPercentString(Math.abs(salesDiff))} vs Prior YTD
                  </li>
                `
            }

            ${compDetail?.compDiff ? `<li>${compDetail.message}</li>` : ''}
          </ul>
        `,
        hasDetails: true,
      }
    }, [
      data,
      ytdData,
      priorYtdData,
      variables,
      groupFilter,
      isCorporate,
      compDetail,
    ]),
    loading: glCodesLoading || metricValuesLoading || compDetailLoading,
  }
}

export default useSales
