import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import useBrands from 'pared/layouts/hooks/useBrands'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
        start_ate: string
        end_date: string
      }[]
    }[]
  }
  listLocationGroups: {
    nodes: {
      locationGroupId: number
      locationGroupName: string
    }[]
  }
}

interface IQueryVariablesType {
  locationGroupIds: [number]
  locationGroupTypes: [string]
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query GetLegalEntity(
    $locationGroupIds: [Int!]!
    $locationGroupTypes: [String!]!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { locaiton_group_ids: $locationGroupIds }
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    listLocationGroups(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_group_types: $locationGroupTypes }
    ) {
      nodes {
        locationGroupId
        locationGroupName
      }
    }
  }
`

const useLegalEntityFilter = () => {
  const { brand } = useBrands()
  const locationGroupId = getBrandLocationGroupId()
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        locationGroupIds: [locationGroupId],
        locationGroupTypes: ['Legal Entity'],
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate,
    },
  )
  const { search } = useLocation()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const legalEntities: { [locationGroupId: string]: string } = {}

      for (const location of data.listLocationGroups?.nodes ?? []) {
        legalEntities[location.locationGroupId] = location.locationGroupName
      }

      const legalEntityOptions = Object.keys(legalEntities).map(
        (locationGroupId) => ({
          id: `legal-entity-${locationGroupId}`,
          parentId: 'root',
          ids: [parseInt(locationGroupId)],
          label: legalEntities[locationGroupId],
          list: data.listLocationDetails.nodes
            .filter((l) =>
              l.locationGroups.some(
                (lg) => lg.id === parseInt(locationGroupId),
              ),
            )
            .map((l) => {
              const queryObject = new URLSearchParams(search)

              queryObject.set('store', l.id.toString())

              const link = `/${brand}/store_detail?${queryObject.toString()}`

              return {
                id: l.id,
                name: `${l.code} - ${l.name}`,
                header: 'Stores',
                link,
                groupBy: undefined,
              }
            }),
        }),
      )

      const values = [
        ...legalEntityOptions,
        ...data.listLocationDetails.nodes.map((l) => {
          return {
            id: l.id.toString(),
            parentId: 'root',
            ids: [l.id],
            label: `${l.code} - ${l.name}`,
          }
        }),
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brand, search]),
    loading,
  }
}

export default useLegalEntityFilter
