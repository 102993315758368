import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

interface IQueryData {
  glCodes: {
    nodes: {
      id: number
      code: string
      name: string
      parentGroupId: number
    }[]
  }

  glGroups: {
    nodes: {
      id: number
      name: string
      parentGroupId: number
      exrayCategory: string | null
    }[]
  }

  locationMetricDefs: {
    nodes: {
      id: number
      code: string
      metadata: {
        glAccountNumber: string
      }
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    glCodes {
      nodes {
        id
        code
        name
        parentGroupId
      }
    }

    glGroups {
      nodes {
        id
        name
        parentGroupId
        exrayCategory
      }
    }

    locationMetricDefs(condition: { metricGroup: "PnL" }) {
      nodes {
        id
        code
        metadata
      }
    }
  }
`

const findAllGlGroups = (
  glGroups: IQueryData['glGroups']['nodes'],
  parentGroups: IQueryData['glGroups']['nodes'],
): IQueryData['glGroups']['nodes'] => {
  if (parentGroups.length === 0) return []

  const ids = parentGroups.map((p) => p.id)
  const childGroups = glGroups.filter((g) => ids.includes(g.parentGroupId))

  return [
    ...parentGroups,
    ...findAllGlGroups(glGroups, childGroups).filter(
      (g) => !ids.includes(g.id),
    ),
  ]
}

const useGlCodes = (
  exrayCategory:
    | 'Sales'
    | 'Expenses'
    | 'Food Cost'
    | 'Labor Cost'
    | 'Comps & Discounts',
) => {
  const { data, loading } = useQuery<IQueryData>(query)

  const glCodes = useMemo(() => {
    const glGroups = data?.glGroups.nodes || []
    const requiredGlGroups = findAllGlGroups(
      glGroups,
      glGroups.filter((n) =>
        exrayCategory !== 'Comps & Discounts'
          ? n.exrayCategory === exrayCategory
          : n.name === 'Comps & Discounts',
      ),
    )
    const requiredGlGroupIds = requiredGlGroups.map((r) => r.id)
    const glCodesData = (data?.glCodes.nodes || []).filter((d) =>
      requiredGlGroupIds.includes(d.parentGroupId),
    )
    const glCodes = glCodesData.map((g) => g.code)
    const metricDefs = data?.locationMetricDefs.nodes.filter((n) =>
      glCodes.includes(n.metadata.glAccountNumber),
    )

    return glCodesData.map((g) => ({
      code: g.code,
      name: g.name,
      metricCode: metricDefs?.find((m) => m.metadata.glAccountNumber === g.code)
        ?.code,
    }))
  }, [data])

  return {
    glCodes,
    loading,
  }
}

export default useGlCodes
