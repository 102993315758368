import DeleteIcon from '@mui/icons-material/Delete'
import { Button, List, ListSubheader } from '@mui/material'
import { useRef, useState } from 'react'

import COLORS from 'pared/constants/colors'

import EditableInput from './EditableInput'

// Recursive rendering of folders
const Folder = ({
  folder,
  onDragStart,
  onDragOver,
  onDrop,
  editFolderName,
  deleteFolder,
}) => {
  const [showChildren, setShowChildren] = useState(false)

  const handleDragStart = (e) => {
    onDragStart(e, folder.id)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    onDrop(e, folder.id)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    onDragOver(e)
  }

  const handleFolderNameEdit = (name) => {
    editFolderName(folder.id, name)
  }

  const handleClick = (e) => {
    e.stopPropagation()
    setShowChildren(!showChildren)
  }

  const handleDeleteGroup = (e) => {
    e.stopPropagation()
    if (folder.children.length === 0) {
      deleteFolder(folder.id)
    }
  }

  return (
    <List
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        border: '1px solid black',
        cursor: 'move',
        padding: '5px',
        margin: '5px',
        backgroundColor: folder.depth % 2 ? COLORS.Smoke : 'white',
      }}
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            backgroundColor: folder.depth % 2 ? COLORS.Smoke : 'white',
          }}
        >
          {folder.type === 'Folder' ? (
            <EditableInput
              value={folder.name}
              updateValue={handleFolderNameEdit}
            />
          ) : (
            folder.name
          )}
          {folder.type === 'Folder' ? (
            <Button color="warning" onClick={handleDeleteGroup}>
              <DeleteIcon />{' '}
            </Button>
          ) : null}
        </ListSubheader>
      }
      onClick={handleClick}
    >
      {/* Render child folders if they exist */}
      {showChildren && folder.children && folder.children.length > 0 && (
        <>
          {folder.children.map((child) => (
            <Folder
              key={child.id}
              folder={child}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
            />
          ))}
        </>
      )}
    </List>
  )
}

export default Folder
