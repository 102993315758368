import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'

const EditableInput = ({ value, updateValue }) => {
  const [isEditing, setIsEditing] = useState(false) // Tracks if the input is in edit mode
  const [inputValue, setInputValue] = useState(value) // Tracks the input value

  // Toggles the editing state
  const toggleEditing = (e) => {
    e.stopPropagation()
    if (isEditing) {
      updateValue(inputValue)
    }

    setIsEditing(!isEditing)
  }

  // Handle input value change
  const handleChange = (event) => {
    setInputValue(event.target.value)
  }

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {/* Edit Button */}

      {/* Input field that is editable only when isEditing is true */}
      <TextField
        value={inputValue}
        onChange={handleChange}
        disabled={!isEditing} // Disable the input unless isEditing is true
        fullWidth
        margin="normal"
      />
      <Button onClick={toggleEditing}>
        {isEditing ? <SaveIcon /> : <EditIcon />}
      </Button>
    </Box>
  )
}

export default EditableInput
