import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useMetricValuesQuery from '../../hooks/useMetricValuesQuery'
import calc from '../../utils/calc'
import { IApiDataType } from '../types'

interface IQueryData {
  glCodes: {
    nodes: {
      id: number
      code: string
      name: string
      parentGroupId: number
      displayOrder: number
    }[]
  }

  glGroups: {
    nodes: {
      id: number
      name: string
      parentGroupId: number
      exrayCategory: string | null
    }[]
  }

  locationMetricDefs: {
    nodes: {
      id: number
      code: string
      metadata: {
        glAccountNumber: string
      }
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    glCodes {
      nodes {
        id
        code
        name
        parentGroupId
        displayOrder
      }
    }

    glGroups {
      nodes {
        id
        name
        parentGroupId
        exrayCategory
      }
    }

    locationMetricDefs(condition: { metricGroup: "PnL" }) {
      nodes {
        id
        code
        metadata
      }
    }
  }
`

const findAllGlGroups = (
  glGroups: IQueryData['glGroups']['nodes'],
  parentGroups: IQueryData['glGroups']['nodes'],
): IQueryData['glGroups']['nodes'] => {
  if (parentGroups.length === 0) return []

  const ids = parentGroups.map((p) => p.id)
  const childGroups = glGroups.filter((g) => ids.includes(g.parentGroupId))

  return [
    ...parentGroups,
    ...findAllGlGroups(glGroups, childGroups).filter(
      (g) => !ids.includes(g.id),
    ),
  ]
}

const useGlCodes = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food Cost' | 'Labor Cost',
) => {
  const { data, loading } = useQuery<IQueryData>(query)

  const glCodes = useMemo(() => {
    const glGroups = data?.glGroups.nodes || []
    const requiredGlGroups = findAllGlGroups(
      glGroups,
      glGroups.filter((n) => n.exrayCategory === exrayCategory),
    )
    const requiredGlGroupIds = requiredGlGroups.map((r) => r.id)
    const glCodesData = (data?.glCodes.nodes || []).filter((d) =>
      requiredGlGroupIds.includes(d.parentGroupId),
    )
    const glCodes = glCodesData.map((g) => g.code)
    const metricDefs = data?.locationMetricDefs.nodes.filter((n) =>
      glCodes.includes(n.metadata.glAccountNumber),
    )

    return glCodesData.map((g) => ({
      code: g.code,
      name: g.name,
      metricCode: metricDefs?.find((m) => m.metadata.glAccountNumber === g.code)
        ?.code,
      displayOrder: g.displayOrder,
    }))
  }, [data])

  return {
    glCodes,
    loading,
  }
}

const buildPnl = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food Cost' | 'Labor Cost',
) => {
  const usePnl = () => {
    const { groupFilter } = useGroupFilter()
    const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory)
    const {
      data,
      yoyData,
      ytdData,
      priorYtdData,
      loading: metricValuesLoading,
    } = useMetricValuesQuery({
      groupFilterTypes: ['listLocation', 'listLocationGroup'],
      metrics: (exrayCategory === 'Sales'
        ? [
            ...glCodes
              .map((g) => g.metricCode && { key: g.metricCode, type: 'ytd' })
              .filter(Boolean),
            ...glCodes
              .map(
                (g) => g.metricCode && { key: g.metricCode, type: 'prior ytd' },
              )
              .filter(Boolean),
          ]
        : [
            ...glCodes.map((g) => g.metricCode).filter(Boolean),
            ...glCodes
              .map((g) => g.metricCode && { key: g.metricCode, type: 'yoy' })
              .filter(Boolean),
          ]) as string[],
      fields: ['metricSummaryData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            location_ids: groupFilter?.ids,
          },
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

    return {
      data: useMemo((): IApiDataType => {
        if (exrayCategory === 'Sales') {
          if (!ytdData) return null
        } else {
          if (!data) return null
        }

        return {
          source: glCodes
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((g) => {
              if (!g.metricCode) return { name: g.name }

              if (exrayCategory === 'Sales') {
                const ytdD = ytdData?.find((d) => d.metricSummaryData)
                  ?.metricSummaryData[g.metricCode].value
                const priorYtdD = priorYtdData?.find((d) => d.metricSummaryData)
                  ?.metricSummaryData[g.metricCode].value
                const variance = calc(ytdD, '-', priorYtdD)

                return {
                  name: g.name,
                  variance,
                  variancePercent: calc(variance, 'percentageOf', priorYtdD),
                }
              }

              const d = data?.find((d) => d.metricSummaryData)
                ?.metricSummaryData[g.metricCode].value
              const yoyD = yoyData?.find((d) => d.metricSummaryData)
                ?.metricSummaryData[g.metricCode].value
              const variance = calc(d, '-', yoyD)

              return {
                name: g.name,
                variance,
                variancePercent: calc(variance, 'percentageOf', yoyD),
              }
            }),
        }
      }, [data, yoyData, ytdData, priorYtdData, glCodes]),
      loading: glCodesLoading || metricValuesLoading,
    }
  }

  return usePnl
}

export const pnlConfigs = {
  name: 'string',
  variance: 'price',
  variancePercent: 'percent',
} as const

export const usePnlSales = buildPnl('Sales')
export const usePnlExpenses = buildPnl('Expenses')
export const usePnlLabor = buildPnl('Labor Cost')
export const usePnlFood = buildPnl('Food Cost')
