import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import {
  IGroupFilterType,
  IGroupFilterVariablesNameType,
  useVariables,
} from '../../../variables'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupMetricValues'
    | 'listLocationMetricValues',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupMetricValues'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData' | 'metricSummaryData', IMetricDataType>)[]
  }
>

type IMetricType = string | { key: string; type: 'yoy' }

const query = gql`
  query metricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
    $hasSummary: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData @include(if: $hasSummary)
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData @include(if: $hasSummary)
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    const metricValue = value?.value ?? null

    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: metricValue === null ? null : value.value * 100,
        }
      case 'SECONDS':
        return {
          ...result,
          [_.camelCase(key)]: metricValue === null ? null : value.value / 60.0,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: metricValue,
        }
    }
  }, {})

const buildMetricValuesHook = (options: {
  groupFilterType: 'corporate' | 'store'
  metrics: { codes?: IMetricType[]; groups?: IMetricType[] }
  handler?: (data: Record<string, unknown>) => Record<string, unknown>
  hasSummary?: boolean
  variablesName?: IGroupFilterVariablesNameType
}) => {
  const handler = options.handler || ((data) => data)
  const hasSummary = options.hasSummary || false
  // FIXME: we should use variables name in the future, not usre group filter
  const variablesName = options.variablesName || 'corporateGroup'

  const useMetricValues = (customOptions?: {
    intersectedLocationGroupIds?: number[]
    startDate?: string
    endDate?: string
    skip?: boolean
  }) => {
    const { variables } = useVariables()

    const dateFilterData = useDateFilter()
    const { startDate, endDate } = useMemo(
      () => ({
        startDate: customOptions?.startDate || dateFilterData.startDate,
        endDate: customOptions?.endDate || dateFilterData.endDate,
      }),
      [customOptions, dateFilterData],
    )

    const groupFilterData = useGroupFilter()
    const variablesData = useMemo(
      () =>
        variables[variablesName] as IGroupFilterType<
          'listLocationGroup' | 'listLocation' | 'location'
        >,
      [variables],
    )

    const { groupFilter, hasGroupBy } = useMemo(() => {
      if (options.variablesName) {
        if (!variablesData) return { groupFilter: null, hasGroupBy: false }

        const intersectedLocationGroupIds = (() => {
          if (customOptions?.intersectedLocationGroupIds)
            return customOptions.intersectedLocationGroupIds

          if (
            'intersectedLocationGroupIds' in variablesData &&
            variablesData.intersectedLocationGroupIds
          )
            return variablesData.intersectedLocationGroupIds
        })()

        return {
          groupFilter: {
            location_group_ids:
              'locationGroupIds' in variablesData
                ? variablesData.locationGroupIds
                : undefined,
            intersected_location_group_ids: intersectedLocationGroupIds,
          },
          hasGroupBy: variablesData.type !== 'listLocationGroup',
        }
      }

      if (!groupFilterData.groupFilter)
        return { groupFilter: null, hasGroupBy: false }

      const hasGroupBy =
        groupFilterData.hasGroupBy || options.groupFilterType === 'store'

      if (options.groupFilterType === 'corporate')
        return {
          groupFilter: {
            location_group_ids: groupFilterData.hasGroupBy
              ? groupFilterData.groupFilter?.ids
              : groupFilterData.groupFilter?.list?.map((g) => g.id),
            intersected_location_group_ids:
              groupFilterData.groupFilter?.intersectedIds,
          },
          hasGroupBy,
        }

      return {
        groupFilter: {
          location_ids: groupFilterData.groupFilter?.ids,
        },
        hasGroupBy,
      }
    }, [groupFilterData])

    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          ...groupFilter,
          metrics:
            options.metrics?.codes
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
          metric_groups:
            options.metrics?.groups
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
        },
        hasGroupBy,
        hasSummary,
      },
      skip:
        customOptions?.skip ||
        !startDate ||
        !endDate ||
        !groupFilter ||
        (!options.metrics?.codes?.some((m) => typeof m === 'string') &&
          !options.metrics?.groups?.some((m) => typeof m === 'string')),
    })
    const { data: yoyData, loading: yoyLoading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          ...groupFilter,
          metrics: options.metrics.codes
            ?.map((m) =>
              typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
            )
            ?.filter(Boolean),
          metric_groups:
            options.metrics.groups
              ?.map((m) =>
                typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
              )
              ?.filter(Boolean) || [],
          use_yoy: true,
        },
        hasGroupBy,
        hasSummary,
      },
      skip:
        customOptions?.skip ||
        !startDate ||
        !endDate ||
        !groupFilter ||
        (!options.metrics?.codes?.some(
          (m) => typeof m !== 'string' && m.type === 'yoy',
        ) &&
          !options.metrics?.groups?.some(
            (m) => typeof m !== 'string' && m.type === 'yoy',
          )),
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const nodes = (
          data.listLocationGroupMetricValues || data.listLocationMetricValues
        ).nodes
        const yoyNodes =
          (
            yoyData?.listLocationGroupMetricValues ||
            yoyData?.listLocationMetricValues
          )?.nodes || []
        const locations =
          variablesData && 'locations' in variablesData
            ? variablesData.locations
            : undefined
        const locationGroups =
          variablesData && 'locationGroups' in variablesData
            ? variablesData.locationGroups
            : undefined
        const summary = {}
        const newData = nodes.map((n) => {
          const id = (() => {
            if (hasGroupBy && locations && 'locationId' in n)
              return n.locationId
            if (!hasGroupBy && locationGroups && 'locationGroupId' in n)
              return n.locationGroupId
            return -1
          })()
          const yoyN = yoyNodes.find((yn) =>
            'locationId' in yn
              ? yn.locationId === id
              : yn.locationGroupId === id,
          )

          const tableRow = (locations || locationGroups)?.[id]?.tableRow

          if (n.metricSummaryData && hasSummary)
            Object.assign(
              summary,
              n.metricSummaryData,
              _.mapKeys(
                yoyN?.metricSummaryData || {},
                (_, key) => `yoy_${key}`,
              ),
            )

          return {
            ...tableRow,
            ...handler(
              format({
                ...n.metricData,
                ..._.mapKeys(yoyN?.metricData || {}, (_, key) => `yoy_${key}`),
              }),
            ),
            id: id.toString(),
            parentId: hasSummary ? 'summary' : 'root',
          }
        })

        return [
          ...(!hasSummary
            ? []
            : [
                {
                  ...handler(format(summary)),
                  ...('tableColumns' in variablesData
                    ? {
                        [variablesData.tableColumns[0].key]: {
                          label: 'TOTAL',
                        },
                      }
                    : {}),
                  id: 'summary',
                  parentId: 'root',
                },
              ]),
          ...newData,
        ]
      }, [variablesData, hasGroupBy, data, yoyData]),
      loading: loading || yoyLoading,
      response: data,
    }
  }

  return useMetricValues
}

export default buildMetricValuesHook
