import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from '../../../groupFilter'
import useMetricValuesQuery from '../../../hooks/useMetricValuesQuery'
import calc from '../../../utils/calc'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

export const pAndLConfigs = {
  pAndLName: 'string',
  pAndLGlCode: 'string',
  pAndLCategory: 'string',
  pAndLType: 'string',
  pAndLSales: 'price',
  pAndLSalesPercent: 'percent',
  pAndLBudget: 'price',
  pAndLBudgetPercent: 'percent',
  pAndLCompare: 'price',
  pAndLComparePercent: 'percent',
  pAndLYoySales: 'price',
  pAndLYoySalesPercent: 'percent',
  pAndLYoyCompare: 'price',
  pAndLYoyComparePercent: 'percent',
  pAndLYtdSales: 'price',
  pAndLYtdSalesPercent: 'percent',
  pAndLYtdBudget: 'price',
  pAndLYtdBudgetPercent: 'percent',
  pAndLYtdCompare: 'price',
  pAndLYtdComparePercent: 'percent',
  pAndLLYYtdSales: 'price',
  pAndLLYYtdSalesPercent: 'percent',
  pAndLLYYtdCompare: 'price',
  pAndLLYYtdComparePercent: 'percent',
  pAndLPriorPeriodSales: 'price',
  pAndLPriorPeriodSalesPercent: 'percent',
  pAndLPriorPeriodCompare: 'price',
  pAndLPriorPeriodComparePercent: 'percent',
  edit: 'button',
  add: 'button',
} as const

const updateGlCodeMutation = gql`
  mutation updateGlCode(
    $id: BigInt!
    $parentGroupId: BigInt!
    $now: Datetime!
  ) {
    updateGlCode(
      input: {
        id: $id
        patch: { parentGroupId: $parentGroupId, updatedAt: $now }
      }
    ) {
      clientMutationId
    }
  }
`

const updateGlGroupMutation = gql`
  mutation updateGlGroup(
    $id: BigInt!
    $parentGroupId: BigInt!
    $now: Datetime!
  ) {
    updateGlGroup(
      input: {
        id: $id
        patch: { parentGroupId: $parentGroupId, updatedAt: $now }
      }
    ) {
      clientMutationId
    }
  }
`

const usePAndL = (): IApiType => {
  const { variables } = useVariables()
  const glData = variables.manageGls.data
  const glCodes = glData?.listGlCodes?.nodes ?? []
  const glGroups = glData?.listGlGroups?.nodes ?? []
  const { groupFilter } = useGroupFilter()
  const isLegalEntity = groupFilter?.id.indexOf('legal-entity') === 0

  const { data, yoyData, ytdData, priorYtdData, priorData, loading } =
    useMetricValuesQuery({
      groupFilterTypes: ['location', 'locationGroup'],
      metricGroups: [
        'PnL',
        { key: 'PnL', type: 'yoy' },
        { key: 'PnL', type: 'ytd' },
        { key: 'PnL', type: 'prior ytd' },
        { key: 'PnL', type: 'prior' },
      ],
      fields: ['metricSummaryData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            ...(isLegalEntity
              ? {
                  location_group_ids: groupFilter?.ids,
                }
              : {
                  location_ids: groupFilter?.ids,
                }),
          },
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter || !newVariables.iStartDate || !newVariables.iEndDate,
          // (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

  const metricData =
    data?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const yoyMetricData =
    yoyData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const ytdMetricData =
    ytdData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const lyYtdMetricData =
    priorYtdData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const priorPeriodMetricData =
    priorData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}

  const [updateGlCode, { loading: updateGlCodeLoading }] = useMutation(
    updateGlCodeMutation,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )

  const [updateGlGroup, { loading: updateGlGroupLoading }] = useMutation(
    updateGlGroupMutation,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )

  const onDrop = async (draggedId: string, droppedId: string) => {
    const id = draggedId.split('-')[1]
    const parentGroupId = droppedId.split('-')[1]

    if (draggedId.indexOf('item') === 0) {
      await updateGlCode({
        variables: {
          id,
          parentGroupId,
          now: moment.utc().format(),
        },
      })
    } else {
      await updateGlGroup({
        variables: {
          id,
          parentGroupId,
          now: moment.utc().format(),
        },
      })
    }
  }

  return {
    data: useMemo(() => {
      if (!metricData) return null
      const getSalesForGlGroup = (glGroupId) => {
        const glNamesInGroup = glCodes
          .filter(({ parentGroupId }) => parentGroupId === glGroupId)
          .map(({ name }) => name)
        const pAndLSales = Object.values(metricData)
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLYoySales = Object.values(yoyMetricData)
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLYoyCompare = calc(pAndLSales, '-', pAndLYoySales)
        const pAndLYtdSales = Object.values(ytdMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)

        const pAndLLYYtdSales = Object.values(lyYtdMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLLYYtdCompare = calc(pAndLYtdSales, '-', pAndLLYYtdSales)

        const pAndLPriorPeriodSales = Object.values(priorPeriodMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLPriorPeriodCompare = calc(
          pAndLSales,
          '-',
          pAndLPriorPeriodSales,
        )

        const glGroupsInGroup = glGroups
          .filter(({ parentGroupId }) => parentGroupId === glGroupId)
          .map(({ id }) => id)
        if (glGroupsInGroup.length === 0) {
          return {
            pAndLSales,
            pAndLYoySales,
            pAndLYoyCompare,
            pAndLYtdSales,
            pAndLLYYtdSales,
            pAndLLYYtdCompare,
            pAndLPriorPeriodSales,
            pAndLPriorPeriodCompare,
          }
        }

        let totalSubSales = 0
        let totalSubYoySales = 0
        let totalSubYoyCompare = 0
        let totalSubYtdSales = 0
        let totalSubLYYtdSales = 0
        let totalSubLYYtdCompare = 0
        let totalSubPriorPeriodSales = 0
        let totalSubPriorPeriodCompare = 0
        for (const glSubgroupId of glGroupsInGroup) {
          const {
            pAndLSales: subSales,
            pAndLYoySales: subYoySales,
            pAndLYoyCompare: subYoyCompare,
            pAndLYtdSales: subYtdSales,
            pAndLLYYtdSales: subLYYtdSales,
            pAndLLYYtdCompare: subLYYtdCompare,
            pAndLPriorPeriodSales: subPriorPeriodSales,
            pAndLPriorPeriodCompare: subPriorPeriodCompare,
          } = getSalesForGlGroup(glSubgroupId)
          totalSubSales = calc(totalSubSales, '+', subSales)
          totalSubYoySales = calc(totalSubYoySales, '+', subYoySales)
          totalSubYoyCompare = calc(totalSubYoyCompare, '+', subYoyCompare)
          totalSubYtdSales = calc(totalSubYtdSales, '+', subYtdSales)
          totalSubLYYtdSales = calc(totalSubLYYtdSales, '+', subLYYtdSales)
          totalSubLYYtdCompare = calc(
            totalSubLYYtdCompare,
            '+',
            subLYYtdCompare,
          )
          totalSubPriorPeriodSales = calc(
            totalSubPriorPeriodSales,
            '+',
            subPriorPeriodSales,
          )
          totalSubPriorPeriodCompare = calc(
            totalSubPriorPeriodCompare,
            '+',
            subPriorPeriodCompare,
          )
        }

        return {
          pAndLSales: calc(pAndLSales, '+', totalSubSales),
          pAndLYoySales: calc(pAndLYoySales, '+', totalSubYoySales),
          pAndLYoyCompare: calc(pAndLYoyCompare, '+', totalSubYoyCompare),
          pAndLYtdSales: calc(pAndLYtdSales, '+', totalSubYtdSales),
          pAndLLYYtdSales: calc(pAndLLYYtdSales, '+', totalSubLYYtdSales),
          pAndLLYYtdCompare: calc(pAndLLYYtdCompare, '+', totalSubLYYtdCompare),
          pAndLPriorPeriodSales: calc(
            pAndLPriorPeriodSales,
            '+',
            totalSubPriorPeriodSales,
          ),
          pAndLPriorPeriodCompare: calc(
            pAndLPriorPeriodCompare,
            '+',
            totalSubPriorPeriodCompare,
          ),
        }
      }

      let totalSales = 0
      let yoyTotalSales = 0
      let totalYoyCompare = 0
      let ytdTotalSales = 0
      let lyYtdTotalSales = 0
      let priorPeriodTotalSales = 0
      let priorPeriodTotalCompare = 0

      const groupRows = []
      for (const glGroup of glGroups) {
        const {
          pAndLSales,
          pAndLYoySales,
          pAndLYoyCompare,
          pAndLYtdSales,
          pAndLLYYtdSales,
          pAndLLYYtdCompare,
          pAndLPriorPeriodSales,
          pAndLPriorPeriodCompare,
        } = getSalesForGlGroup(glGroup.id)

        // FIXME: Hard coded
        if (glGroup.id === '1') {
          totalSales = pAndLSales
          yoyTotalSales = pAndLYoySales
          totalYoyCompare = pAndLYoyCompare
          ytdTotalSales = pAndLYtdSales
          lyYtdTotalSales = pAndLLYYtdSales
          priorPeriodTotalSales = pAndLPriorPeriodSales
          priorPeriodTotalCompare = pAndLPriorPeriodCompare
        }

        groupRows.push({
          id: `folder-${glGroup.id}`,
          parentId:
            glGroup.parentGroupId === null
              ? 'root'
              : `folder-${glGroup.parentGroupId}`,
          pAndLName: glGroup.name,
          pAndLSales,
          pAndLSalesPercent: calc(pAndLSales, 'percentageOf', totalSales),
          pAndLYoySales,
          pAndLYoySalesPercent: calc(
            pAndLYoySales,
            'percentageOf',
            yoyTotalSales,
          ),
          pAndLYoyCompare,
          pAndLYoyComparePercent: calc(
            pAndLYoyCompare,
            'percentageOf',
            Math.abs(pAndLYoySales),
          ),
          pAndLYtdSales,
          pAndLYtdSalesPercent: calc(
            pAndLYtdSales,
            'percentageOf',
            ytdTotalSales,
          ),
          pAndLLYYtdSales,
          pAndLLYYtdSalesPercent: calc(
            pAndLLYYtdSales,
            'percentageOf',
            lyYtdTotalSales,
          ),
          pAndLLYYtdCompare,
          pAndLLYYtdComparePercent: calc(
            pAndLLYYtdCompare,
            'percentageOf',
            Math.abs(pAndLLYYtdSales),
          ),
          pAndLPriorPeriodSales,
          pAndLPriorPeriodSalesPercent: calc(
            pAndLPriorPeriodSales,
            'percentageOf',
            priorPeriodTotalSales,
          ),
          pAndLPriorPeriodCompare,
          pAndLPriorPeriodComparePercent: calc(
            pAndLPriorPeriodCompare,
            'percentageOf',
            Math.abs(pAndLPriorPeriodSales),
          ),
          edit: 'EDIT',
          add: 'ADD',
          displayOrder: glGroup.displayOrder,
        })
      }

      const itemRows = []
      for (const glCode of glCodes) {
        const metric = Object.values(metricData).find(
          ({ name }) => glCode.name === name,
        )
        const yoyMetric = Object.values(yoyMetricData).find(
          ({ name }) => glCode.name === name,
        )
        const ytdMetric = Object.values(ytdMetricData ?? {}).find(
          ({ name }) => glCode.name === name,
        )
        const lyYtdMetric = Object.values(lyYtdMetricData ?? {}).find(
          ({ name }) => glCode.name === name,
        )
        const priorPeriodMetric = Object.values(
          priorPeriodMetricData ?? {},
        ).find(({ name }) => glCode.name === name)
        const item = {
          id: `item-${glCode.id}`,
          parentId: `folder-${glCode.parentGroupId}`,
          pAndLName: `${glCode.code} - ${glCode.name}`,
          pAndLGlCode: glCode.code,
          pAndLSales: metric?.value,
          pAndLSalesPercent: calc(metric?.value, 'percentageOf', totalSales),
          pAndLYoySales: yoyMetric?.value,
          pAndLYoySalesPercent: calc(
            yoyMetric?.value,
            'percentageOf',
            yoyTotalSales,
          ),
          pAndLYoyCompare: calc(metric?.value, '-', yoyMetric?.value),
          pAndLYoyComparePercent: calc(
            calc(metric?.value, '-', yoyMetric?.value),
            'percentageOf',
            Math.abs(yoyMetric?.value),
          ),
          pAndLYtdSales: ytdMetric?.value,
          pAndLYtdSalesPercent: calc(
            ytdMetric?.value,
            'percentageOf',
            ytdTotalSales,
          ),
          pAndLLYYtdSales: lyYtdMetric?.value,
          pAndLLYYtdSalesPercent: calc(
            lyYtdMetric?.value,
            'percentageOf',
            lyYtdTotalSales,
          ),
          pAndLLYYtdCompare: calc(ytdMetric?.value, '-', lyYtdMetric?.value),
          pAndLLYYtdComparePercent: calc(
            calc(ytdMetric?.value, '-', lyYtdMetric?.value),
            'percentageOf',
            Math.abs(lyYtdMetric?.value),
          ),
          pAndLPriorPeriodSales: priorPeriodMetric?.value,
          pAndLPriorPeriodSalesPercent: calc(
            priorPeriodMetric?.value,
            'percentageOf',
            priorPeriodTotalSales,
          ),
          pAndLPriorPeriodCompare: calc(
            metric?.value,
            '-',
            priorPeriodMetric?.value,
          ),
          pAndLPriorPeriodComparePercent: calc(
            calc(metric?.value, '-', priorPeriodMetric?.value),
            'percentageOf',
            Math.abs(priorPeriodMetric?.value),
          ),
          displayOrder: glCode.displayOrder,
        }

        itemRows.push(item)
      }

      const rows = [...itemRows, ...groupRows].sort(
        (a, b) => a.displayOrder - b.displayOrder,
      )

      const {
        pAndLSales,
        pAndLYoySales,
        pAndLYoyCompare,
        pAndLYtdSales,
        pAndLLYYtdSales,
        pAndLLYYtdCompare,
        pAndLPriorPeriodSales,
        pAndLPriorPeriodCompare,
      } = getSalesForGlGroup('41')
      rows.push({
        id: 'netProfit',
        parentId: 'root',
        pAndLName: 'Net Profit',
        pAndLSales,
        pAndLSalesPercent: calc(pAndLSales, 'percentageOf', totalSales),
        pAndLYoySales,
        pAndLYoySalesPercent: calc(
          pAndLYoySales,
          'percentageOf',
          yoyTotalSales,
        ),
        pAndLYoyCompare,
        pAndLYoyComparePercent: calc(
          pAndLYoyCompare,
          'percentageOf',
          Math.abs(pAndLYoySales),
        ),
        pAndLYtdSales,
        pAndLYtdSalesPercent: calc(
          pAndLYtdSales,
          'percentageOf',
          ytdTotalSales,
        ),
        pAndLLYYtdSales,
        pAndLLYYtdSalesPercent: calc(
          pAndLLYYtdSales,
          'percentageOf',
          lyYtdTotalSales,
        ),
        pAndLLYYtdCompare,
        pAndLLYYtdComparePercent: calc(
          pAndLLYYtdCompare,
          'percentageOf',
          Math.abs(pAndLLYYtdSales),
        ),
        pAndLPriorPeriodSales,
        pAndLPriorPeriodSalesPercent: calc(
          pAndLPriorPeriodSales,
          'percentageOf',
          priorPeriodTotalSales,
        ),
        pAndLPriorPeriodCompare,
        pAndLPriorPeriodComparePercent: calc(
          pAndLPriorPeriodCompare,
          'percentageOf',
          Math.abs(pAndLPriorPeriodSales),
        ),
        isFrozen: true,
      })
      return rows
    }, [data, yoyData, ytdData, priorYtdData, variables.manageGls]),
    loading: loading || updateGlGroupLoading || updateGlCodeLoading,
    onDrop,
  }
}

export default usePAndL
