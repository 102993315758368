import { gql, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import useBrands from 'pared/layouts/hooks/useBrands'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'addGlGroup'
  locationGroupType: string
}

export interface IDataType {
  locationGroupType: string
  value: string
  onChange: (value: IDataType['value']) => void
  loading: boolean
  errorMessage?: string
  submit: () => Promise<void>
}

const createMutate = gql`
  mutation CreateGlGroup($name: String!, $parentGroupId: BigInt!) {
    createGlGroup(
      input: { glGroup: { name: $name, parentGroupId: $parentGroupId } }
    ) {
      glGroup {
        id
      }
    }
  }
`

const useAddGlGroup = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const addGlGroupHook = useState<string>('')
  const isSubmittingHook = useState(false)
  const prevManageType = usePrevious(option?.manageType)
  const { brand } = useBrands()
  const [createGlGroup, { loading }] = useMutation(createMutate, {
    refetchQueries: ['ListGlCodesAndGroups'],
    awaitRefetchQueries: true,
  })
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listGlGroups.nodes || []).reduce(
            (result, n) =>
              result.some((r) => r === n.name) ? result : [...result, n.name],
            [] as string[],
          ),
    [data, option],
  )

  useEffect(() => {
    if (
      prevManageType !== option?.manageType &&
      option?.manageType !== 'addGlGroup'
    ) {
      addGlGroupHook[1]('')
      isSubmittingHook[1](false)
    }
  }, [option, prevManageType, addGlGroupHook, isSubmittingHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'addGlGroup') return

    const [value, onChange] = addGlGroupHook
    const [isSubmitting, setIsSubmitting] = isSubmittingHook
    const validator = (isSubmitting: boolean) => {
      if (!value && isSubmitting) return `Couldn't be empty`

      if (options.includes(value)) return `${value} exists`
    }

    return {
      glGroupName: option.pAndLName,
      value,
      onChange,
      loading,
      errorMessage: validator(isSubmitting),
      submit: async () => {
        const errorMessage = validator(true)
        const hasError = Boolean(errorMessage)

        if (!isSubmitting) setIsSubmitting(true)

        if (loading || hasError) return

        await createGlGroup({
          variables: {
            parentGroupId: option.id.split('-')[1],
            name: value,
          },
        })
        setIsSubmitting(false)
        close()
      },
    }
  }, [option, close, addGlGroupHook, isSubmittingHook, loading, brand, options])
}

export default useAddGlGroup
