import { gql, useQuery } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useAddGlGroup, {
  IDataType as IAddGlGroupDataType,
  IOptionType as IAddGlGroupOptionType,
} from './useAddGlGroup'
import useEditGlGroup, {
  IDataType as IEditGlGroupDataType,
  IOptionType as IEditGlGroupOptionType,
} from './useEditGlGroup'

const query = gql`
  query ListGlCodesAndGroups {
    listGlCodes {
      nodes {
        id
        code
        name
        parentGroupId
        displayOrder
      }
    }

    listGlGroups {
      nodes {
        id
        name
        parentGroupId
        displayOrder
      }
    }
  }
`

const extractPairFromFolder = (folder) => {
  const groupPairs = []
  const glPairs = []
  if (folder.type === 'Folder') {
    groupPairs.push({
      id: folder.id.split('-')[1],
      name: folder.name,
      parent_group_id:
        folder.parentId === null ? null : folder.parentId.split('-')[1],
    })
  }

  if (folder.type === 'Item') {
    glPairs.push({
      id: folder.id.split('-')[1],
      parent_group_id:
        folder.parentId === null ? null : folder.parentId.split('-')[1],
    })
  }

  if (folder.children) {
    for (const childFolder of folder.children) {
      const childPairs = extractPairFromFolder(childFolder)
      groupPairs.push(...childPairs.groupPairs)
      glPairs.push(...childPairs.glPairs)
    }
  }

  return {
    groupPairs,
    glPairs,
  }
}

const useManageGls = (): IDataType => {
  const editModeHook = useState(false)
  const manageHook = useState<IStateType | null>(null)
  const onClose = useCallback(() => manageHook[1](null), [manageHook])
  const { data, loading } = useQuery(query)

  useEffect(() => {
    if (data) {
      const glGroups = data?.listGlGroups?.nodes ?? []
      const glCodes = data?.listGlCodes?.nodes ?? []
      const folders = [
        ...glGroups.map(({ id, name, parentGroupId, displayOrder }) => ({
          id: `folder-${id}`,
          name,
          parentId: parentGroupId ? `folder-${parentGroupId}` : null,
          children: [],
          type: 'Folder',
          displayOrder,
        })),
        ...glCodes.map(({ id, code, name, parentGroupId, displayOrder }) => ({
          id: `item-${id}`,
          name: `${code} - ${name}`,
          parentId: parentGroupId ? `folder-${parentGroupId}` : null,
          children: [],
          type: 'Item',
          displayOrder,
        })),
      ]

      for (const folder of folders) {
        const parent = folders.find(({ id }) => id === folder.parentId)
        if (parent) {
          parent.children.push(folder)
        }
      }
    }
  }, [data])

  const addGlGroup = useAddGlGroup(
    data || null,
    manageHook[0] as IAddGlGroupOptionType,
    onClose,
  )

  const editGlGroup = useEditGlGroup(
    data || null,
    manageHook[0] as IEditGlGroupOptionType,
    onClose,
  )

  return {
    addGlGroup,
    editGlGroup,
    manageGls: useMemo((): IDataType['manageGls'] => {
      const [manageState, setManageState] = manageHook
      return {
        loading,
        addGlGroupOpen: manageState?.manageType === 'addGlGroup',
        editGlGroupOpen: manageState?.manageType === 'editGlGroup',
        onOpenAdd: ({ values }) => {
          setManageState({
            ...values,
            manageType: 'addGlGroup',
          })
        },
        onOpenEdit: ({ values }) => {
          setManageState({
            ...values,
            manageType: 'editGlGroup',
          })
        },
        onClose,
        data,
        editMode: editModeHook[0],
        onToggleEditMode: () => {
          editModeHook[1](!editModeHook[0])
        },
      }
    }, [manageHook, onClose]),
  }
}

export default useManageGls
