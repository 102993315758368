import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useVariables } from '../variables'
import FolderContainer from './components/FolderContainer'

export interface IPropsType {
  api: string
}

function App({ api }: IPropsType) {
  const { variables } = useVariables()
  const folders = variables?.[api]?.folders ?? []
  const setFolders = variables?.[api]?.setFolders

  return (
    <DndProvider backend={HTML5Backend}>
      <FolderContainer folders={folders} setFolders={setFolders} />
    </DndProvider>
  )
}

export default App
