import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useMetricValuesQuery from '../../hooks/useMetricValuesQuery'
import calc from '../../utils/calc'
import { IApiDataType } from '../types'

interface IQueryData {
  glCodes: {
    nodes: {
      id: number
      code: string
      name: string
      parentGroupId: number
    }[]
  }

  glGroups: {
    nodes: {
      id: number
      name: string
      parentGroupId: number
      exrayCategory: string | null
    }[]
  }

  locationMetricDefs: {
    nodes: {
      id: number
      code: string
      metadata: {
        glAccountNumber: string
      }
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    glCodes {
      nodes {
        id
        code
        name
        parentGroupId
      }
    }

    glGroups {
      nodes {
        id
        name
        parentGroupId
        exrayCategory
      }
    }

    locationMetricDefs(condition: { metricGroup: "PnL" }) {
      nodes {
        id
        code
        metadata
      }
    }
  }
`

const findAllGlGroups = (
  glGroups: IQueryData['glGroups']['nodes'],
  parentGroups: IQueryData['glGroups']['nodes'],
): IQueryData['glGroups']['nodes'] => {
  if (parentGroups.length === 0) return []

  const ids = parentGroups.map((p) => p.id)
  const childGroups = glGroups.filter((g) => ids.includes(g.parentGroupId))

  return [
    ...parentGroups,
    ...findAllGlGroups(glGroups, childGroups).filter(
      (g) => !ids.includes(g.id),
    ),
  ]
}

const useGlCodes = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food' | 'Labor' | 'Comps & Discounts',
) => {
  const { data, loading } = useQuery<IQueryData>(query)

  const glCodes = useMemo(() => {
    const glGroups = data?.glGroups.nodes || []
    const requiredGlGroups = findAllGlGroups(
      glGroups,
      glGroups.filter((n) =>
        exrayCategory !== 'Comps & Discounts'
          ? n.exrayCategory === exrayCategory
          : n.name === 'Comps & Discounts',
      ),
    )
    const requiredGlGroupIds = requiredGlGroups.map((r) => r.id)
    const glCodesData = (data?.glCodes.nodes || []).filter((d) =>
      requiredGlGroupIds.includes(d.parentGroupId),
    )
    const glCodes = glCodesData.map((g) => g.code)
    const metricDefs = data?.locationMetricDefs.nodes.filter((n) =>
      glCodes.includes(n.metadata.glAccountNumber),
    )

    return glCodesData.map((g) => ({
      code: g.code,
      name: g.name,
      metricCode: metricDefs?.find((m) => m.metadata.glAccountNumber === g.code)
        ?.code,
    }))
  }, [data])

  return {
    glCodes,
    loading,
  }
}

const buildCorporatePnl = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food' | 'Labor',
) => {
  const useCorporatePnl = () => {
    const { groupFilter, hasGroupBy } = useGroupFilter()
    const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory)
    const {
      ytdData,
      priorYtdData,
      loading: metricValuesLoading,
    } = useMetricValuesQuery({
      groupFilterTypes: ['listLocation', 'listLocationGroup'],
      metrics: [
        ...glCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'ytd' })
          .filter(Boolean),
        ...glCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'prior ytd' })
          .filter(Boolean),
      ] as string[],
      fields: ['metricData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            location_group_ids: groupFilter?.ids,
          },
          isLocationGroup: !hasGroupBy,
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!ytdData) return null

        return {
          source: ytdData.map((d) => {
            const groupInfo = groupFilter?.list?.find((g) =>
              'locationId' in d
                ? d.locationId === g.id
                : d.locationGroupId === g.id,
            )
            const priorYtdD = priorYtdData?.find((p) => {
              if ('locationId' in d && 'locationId' in p)
                return d.locationId === p.locationId
              if ('locationGroupId' in d && 'locationGroupId' in p)
                return d.locationGroupId === p.locationGroupId
            })

            const ytdTotal =
              Object.values(d.metricData).reduce(
                (subResult, { value }) => subResult + (value || 0),
                0,
              ) * (exrayCategory === 'Sales' ? 1 : -1)
            const priorYtdTotal =
              Object.values(priorYtdD?.metricData || {}).reduce(
                (subResult, { value }) => subResult + (value || 0),
                0,
              ) * (exrayCategory === 'Sales' ? 1 : -1)

            const [isYtdNotEmpty, isPriorYtdNotEmpty] = [d, priorYtdD].map(
              (data) =>
                Object.values(data?.metricData || {}).some(
                  ({ value }) => !_.isNil(value),
                ),
            )

            if (!isYtdNotEmpty || !isPriorYtdNotEmpty) return { groupInfo }

            const variance = calc(ytdTotal, '-', priorYtdTotal)

            return {
              groupInfo,
              variance,
              variancePercent: calc(variance, 'percentageOf', priorYtdTotal),
            }
          }),
        }
      }, [ytdData, priorYtdData, groupFilter, hasGroupBy]),
      loading: glCodesLoading || metricValuesLoading,
    }
  }

  return useCorporatePnl
}

export const corporatePnlConfigs = {
  variance: 'price',
  variancePercent: 'percent',
} as const

export const useCorporatePnlSales = buildCorporatePnl('Sales')
export const useCorporatePnlExpenses = buildCorporatePnl('Expenses')
export const useCorporatePnlLabor = buildCorporatePnl('Labor')
export const useCorporatePnlFood = buildCorporatePnl('Food')
